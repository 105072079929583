@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700&display=swap");

.topBar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5vh 0 5vh 50%;
  transform: translateX(-50%);
  gap: 50px;
  position: relative;
  width: max-content;
  padding: 0 5vw;

  border: 1px solid rgba(255, 255, 255, 0.5);
  // border-bottom-color: linear-gradient(red, blue);
  background: linear-gradient(to right, rgba(68, 67, 67, 0.6), transparent);
  border-radius: 30px;

  .chainButtonsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;

    button {
      font-family: "Montserrat", sans-serif;
      font-weight: 800;
      font-size: large;
      background: transparent;
      color: white;
      border: none;
      opacity: 0.2;
      transition: 300ms;
      transform: scale(0.9);

      border: 2px solid rgb(125, 50, 136);
      border-radius: 5px;

      &.active {
        transform: scale(1.2);
        opacity: 1;

        &:focus,
        &:hover {
          opacity: 1;
        }
      }

      &:focus,
      &:hover {
        opacity: 0.8;
      }

      padding: 10px;
      cursor: pointer;
    }
  }

  .imageContainer {
    position: relative;
    width: 250px;
    height: 200px;
    transform: translateY(-20px);
    animation: 7s float ease-in-out infinite;

    img {
      position: absolute;
      width: 250px;
      opacity: 0;
      transition: 300ms;

      &.active {
        opacity: 1;
      }
    }

    img:first-of-type {
      width: 220px;
      transform: scale(1) rotateZ(30deg);
    }

    img:last-of-type {
      width: 270px;
      transform: translateX(-10%) translateY(-10%);
    }
  }

  .textContainer {
    max-width: 600px;

    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
    font-family: "Montserrat", sans-serif;

    .title {
      font-size: x-large;
    }
  }
}

@keyframes float {
  0%,
  100% {
    transform: translateY(-20px);
  }

  50% {
    transform: translateY(+5px);
  }
}

.table {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  font-family: "Montserrat", sans-serif;
  font-size: larger;

  .tableBody {
    .tableRow {
      // background-color: #f2f2f2;
      padding: 5px 0;

      &:hover {
        background: linear-gradient(40deg, rgb(125, 50, 136), transparent);
      }
    }

    .tableCell {
      &:first-of-type {
        font-weight: 800;

        &::before {
          content: "#";
        }
      }

      &:last-of-type {
        font-weight: 800;
      }
    }
  }

  .tableRow {
    display: flex;
    border-bottom: 1px solid #ffffff77;

    &.header {
      border-bottom: 2px solid white;
      font-weight: 800;
    }

    .tableCell {
      flex: 1;
      padding: 8px;

      display: flex;
      align-items: center;
      &:last-child {
        width: 20px;
        max-width: 20px;
        text-align: right;
        justify-content: flex-end;
      }

      &.name {
        flex: 2;

        img {
          width: 30px;
          height: 30px;
          object-fit: cover;
          margin-right: 20px;
        }
      }
      .fixStatus {
        width: 10px;
        height: 10px;
        border-radius: 50%;

        &.invalid {
          background-color: #e74c3c;
        }
        &.valid {
          background-color: #2ecc71;
        }
        &.paused {
          background-color: #f1c40f;
        }
      }
    }
  }
}

footer {
  font-family: "Montserrat", sans-serif;
  margin-top: 10vh;
  padding: 2vh 0;
  text-align: center;
  font-size: large;
  font-weight: 400;
  color: rgb(136, 74, 146);

  a {
    color: rgb(125, 50, 136);
    text-decoration: none;

    &:hover {
      color: rgb(125, 50, 136);
      color: white;
    }
  }
}
